
// Text inputs
input:not([type]),
input[type=text],
input[type=password],
input[type=email],
input[type=url],
input[type=time],
input[type=date],
input[type=datetime-local],
input[type=tel],
input[type=number],
input[type=search],
textarea.materialize-textarea {

  // General Styles
  background-color: transparent;
  border: none;
  border-bottom: 1px solid $input-border-color;
  border-radius: 0;
  outline: none;
  height: 3rem;
  width: 100%;

  font-size: 1rem;
  margin: 0 0 15px 0;
  padding: 0;
  box-shadow: none;
  box-sizing: content-box;
  transition: all .3s;


  // Disabled input style
  &:disabled, &[readonly="readonly"] {
    color: $input-disabled-color;
    border-bottom: 1px dotted $input-disabled-color;
  }
  // Disabled label style
  &:disabled+label, &[readonly="readonly"]+label {
    color: $input-disabled-color;
  }
  // Focused input style
  &:focus:not([readonly]) {
    border-bottom: 1px solid $input-focus-color;
    box-shadow: 0 1px 0 0 $input-focus-color;
  }
  // Focused label style
  &:focus:not([readonly])+label {
    color: $input-focus-color;
  }
  // Valid Input Style
  &.valid,
  &:focus.valid {
    border-bottom: 1px solid $input-success-color;
    box-shadow: 0 1px 0 0 $input-success-color;
  }
  // Custom Success Message
  &.valid + label:after,
  &:focus.valid + label:after {
    content: attr(data-success);
    color: $input-success-color;
    opacity: 1;
  }
  // Invalid Input Style
  &.invalid,
  &:focus.invalid {
    border-bottom: 1px solid $input-error-color;
    box-shadow: 0 1px 0 0 $input-error-color;
  }
  // Custom Error message
  &.invalid + label:after,
  &:focus.invalid + label:after {
    content: attr(data-error);
    color: $input-error-color;
    opacity: 1;
  }

  // Form Message Shared Styles
  & + label:after {
    display: block;
    content: "";
    position: absolute;
    top: 65px;
    opacity: 0;
    transition: .2s opacity ease-out, .2s color ease-out;
  }
}

// Styling for input field wrapper
.input-field {
  position: relative;
  margin-top: 1rem;

  label {
    color: $input-border-color;
    position: absolute;
    top: 0.8rem;
    left: $gutter-width / 2;
    font-size: 1rem;
    cursor: text;
    transition: .2s ease-out;
  }
  label.active {
    font-size: $label-font-size;
    transform: translateY(-140%);
  }

  // Prefix Icons
  .prefix {
    position: absolute;
    width: 3rem;
    font-size: 2rem;
    transition: color .2s;

    &.active { color: $input-focus-color; }
  }
  .prefix ~ input,
  .prefix ~ textarea {
    margin-left: 3rem;
    width: 92%;
    width: calc(100% - 3rem);
  }
  .prefix ~ textarea { padding-top: .8rem; }
  .prefix ~ label { margin-left: 3rem; }

  @media #{$medium-and-down} {
    .prefix ~ input {
      width: 86%;
      width: calc(100% - 3rem);
    }
  }
  @media #{$small-and-down} {
    .prefix ~ input {
      width: 80%;
      width: calc(100% - 3rem);
    }
  }
}

// Search Field
.input-field input[type=search] {
  display: block;
  line-height: inherit;
  padding-left: 4rem;
  width: calc(100% - 4rem);

  &:focus {
    background-color: $input-bg-color;
    border: 0;
    box-shadow: none;
    color: #444;

    & + label i,
    & ~ .mdi-navigation-close,
    & ~ .material-icons {
      color: #444;
    }
  }

  & + label {
    left: 1rem;
  }

  & ~ .mdi-navigation-close,
  & ~ .material-icons {
    position: absolute;
    top: 0;
    right: 1rem;
    color: transparent;
    cursor: pointer;
    font-size: 2rem;
    transition: .3s color;
  }
}


// Default textarea
textarea {
   width: 100%;
   height: 3rem;
   background-color: transparent;

  &.materialize-textarea {
    overflow-y: hidden; /* prevents scroll bar flash */
    padding: 1.6rem 0; /* prevents text jump on Enter keypress */
    resize: none;
    min-height: 3rem;
  }
}

// For textarea autoresize
.hiddendiv {
  display: none;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word; /* future version of deprecated 'word-wrap' */
  padding-top: 1.2rem; /* prevents text jump on Enter keypress */
}


/***************
  Select Field
***************/
.select-label {
  position: absolute;
}

.select-wrapper {
  position: relative;

  input.select-dropdown {
    position: relative;
    cursor: pointer;
    // color: #444;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $input-border-color;
    outline: none;
    height: 3rem;
    line-height: 3rem;
    width: 100%;
    font-size: 1rem;
    margin: 0 0 15px 0;
    padding: 0;
    display: block;
  }

  span.caret {
    color: initial;
    position: absolute;
    right: 0;
    top: 16px;
    font-size: 10px;
    &.disabled {
      color: $input-disabled-color;
    }
  }

  & + label {
    position: absolute;
    top: -14px;
    font-size: $label-font-size;
  }
}

select { display: none; }
select.browser-default { display: block; }

// Disabled styles
select:disabled {
  color: rgba(0,0,0,.3);
}

.select-wrapper input.select-dropdown:disabled {
  color: rgba(0,0,0,.3);
  cursor: default;
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -ms-user-select: none; /* IE10+ */
  border-bottom: 1px solid rgba(0,0,0,.3);
}

.select-wrapper i {
  color: rgba(0,0,0,.3);
}

.select-dropdown li.disabled,
.select-dropdown li.disabled > span,
.select-dropdown li.optgroup {
  color: rgba(0,0,0,.3);
  background-color: transparent;
}

// Icons
.select-dropdown li {
  img {
    height: $dropdown-item-height - 10;
    width: $dropdown-item-height - 10;
    margin: 5px 15px;
    float: right;
  }
}

// Optgroup styles
.select-dropdown li.optgroup {
  border-top: 1px solid $dropdown-hover-bg-color;

  &.selected > span {
    color: rgba(0, 0, 0, .7);
  }

  & > span {
    color: rgba(0, 0, 0, .4);
  }

  & ~ li:not(.optgroup) {
    padding-left: 1rem;
  }
}

/*********************
      File Input
**********************/
.file-field {
  position: relative;

  .file-path-wrapper {
    overflow: hidden;
    padding-left: 10px;
  }

  input.file-path { width: 100%; }

  .btn {
    float: left;
    height: 3rem;
    line-height: 3rem;
  }

  span {
    cursor: pointer;
  }

  input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }
}
