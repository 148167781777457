.header {
  @extend .valign-wrapper;
  color: white;
  background-size: cover;
  background-position: center;
  background-color: $primary-color;
  width: 100%;
  min-height: 400px;
  padding: 70px 20px 20px 20px;
  text-align: center;
  vertical-align: center;
  div {
    margin: auto;
  }
  h1, h2, h3, h4, h5, h6 {
    color: white
  }
}


.header-expertise {
  @extend .header;
  background-image: url('/assets/images/headers/experts.jpg');
}


.header-news {
  @extend .header;
  background-image: url('/assets/images/headers/news.png');
}

.header-legal {
  @extend .header;
  min-height: 200px;
}


.header-choco {
  @extend .header;
  background-image: url('/assets/images/headers/choco.png');
}

.header-cpsat {
  @extend .header;
  background-image: url('/assets/images/headers/cpsat-banner.png');
}

.header-workforce {
    @extend .header;
    background-image: linear-gradient( rgba(60, 115, 165, 0.5), rgba(60, 115, 165, 0.5) ), url("/assets/images/headers/people.png");
}

.header-config {
  @extend .header;
  background-image: url("/assets/images/headers/consultant.jpg");
}