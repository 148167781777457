//Default styles

html {
 box-sizing: border-box;
}
*, *:before, *:after {
 box-sizing: inherit;
}

body {
  // display: flex;
  // min-height: 100vh;
  // flex-direction: column;
}

main {
  // flex: 1 0 auto;
}

ul {
	list-style-type: none;
}

a {
	color: $link-color;
	text-decoration: none;

  // Gets rid of tap active state
  -webkit-tap-highlight-color: transparent;
}


// Positioning
.valign-wrapper {
  display: flex;
  align-items: center;

  .valign {
    display: block;
  }
}


ul {
  padding: 0;
  li {
    list-style-type: none;
  }
}

// Z-levels
.z-depth-0 {
  box-shadow: none !important;
}
.z-depth-1{
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.z-depth-1-half{
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.z-depth-2{
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.z-depth-3{
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.z-depth-4{
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
}
.z-depth-5{
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
}

.hoverable {
  transition: box-shadow .25s;
  box-shadow: 0;
}

.hoverable:hover {
  transition: box-shadow .25s;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

// Icon Styles

i {
  line-height: inherit;

  &.right {
    float: right;
    margin-left: 15px;
  }
}

// Images
img.responsive-img,
video.responsive-video {
  max-width: 100%;
  height: auto;
}



/*********************
  Media Query Classes
**********************/
.hide-on-small-only, .hide-on-small-and-down {
  @media #{$small-and-down} {
    display: none !important;
  }
}
.hide-on-med-and-down {
  @media #{$medium-and-down} {
    display: none !important;
  }
}
.hide-on-med-and-up {
  @media #{$medium-and-up} {
    display: none !important;
  }
}
.hide-on-med-only {
  @media only screen and (min-width: $small-screen) and (max-width: $medium-screen) {
    display: none !important;
  }
}
.hide-on-large-only {
  @media #{$large-and-up} {
    display: none !important;
  }
}
.show-on-large {
  @media #{$large-and-up} {
    display: block !important;
  }
}
.show-on-medium {
  @media only screen and (min-width: $small-screen) and (max-width: $medium-screen) {
    display: block !important;
  }
}
.show-on-small {
  @media #{$small-and-down} {
    display: block !important;
  }
}
.show-on-medium-and-up {
  @media #{$medium-and-up} {
    display: block !important;
  }
}
.show-on-medium-and-down {
  @media #{$medium-and-down} {
    display: block !important;
  }
}


// Footer
footer.page-footer {
  margin-top: 20px;
  padding-top: 20px;
  background-color: $footer-bg-color;
}

// Tables
table, th, td {
   border: none;
}

table {
  width:100%;
  display: table;
}

td, th{
  padding: 15px 5px;
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  border-radius: 2px;
}


// Collections
.collection {
  margin: $element-top-margin 0 $element-bottom-margin 0;
  border: 1px solid $collection-border-color;
  border-radius: 2px;
  overflow: hidden;
  position: relative;

  .collection-item {
    background-color: $collection-bg-color;
    line-height: 1.5rem;
    padding: 10px 20px;
    margin: 0;
    border-bottom: 1px solid $collection-border-color;

    &:last-child {
      border-bottom: none;
    }
  }
  a.collection-item{
    display: block;
    transition: .25s;
    color: $collection-link-color;
    &:not(.active) {
      &:hover {
        background-color: $collection-hover-bg-color;
      }
    }
  }

  &.with-header {
    .collection-header {
      background-color: $collection-bg-color;
      border-bottom: 1px solid $collection-border-color;
      padding: 10px 20px;
    }
    .collection-item {
      padding-left: 30px;
    }
    .collection-item.avatar {
      padding-left: 72px;
    }
  }

}

/*******************
  Utility Classes
*******************/

.hide {
  display: none !important;
}

// Text Align
.left-align {
  text-align: left;
}
.right-align {
  text-align: right
}
.center, .center-align {
  text-align: center;
}

.left {
  float: left !important;
}
.right {
  float: right !important;
}

// No Text Select
.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.circle {
  border-radius: 50%;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.truncate {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-padding {
  padding: 0 !important;
}
