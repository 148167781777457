.sliderHeader {
  @extend .slider;
  h1, h2, h3, h4, h5, h6, em, a, .icon {
    color: white
  }
}

.slider .indicators .indicator-item.active {
  background-color: $primary-color;
}

.slider .slides li .caption {
  position: absolute;
  top: 33%;
}
