.side-nav {
  position: fixed;
  width: 240px;
  left: -105%;
  top: 0;
  margin: 0;
  height: 100%;
  height: calc(100% + 60px);
  height: -moz-calc(100%); //Temporary Firefox Fix
  padding-bottom: 60px;
  background-color: $sidenav-bg-color;
  z-index: 999;
  overflow-y: auto;

  @extend .z-depth-1;
  will-change: left;

  // Right Align
  &.right-aligned {
    will-change: right;
    right: -105%;
    left: auto;
  }

  .collapsible{
    margin: 0;
  }


  li {
    float: none;
    padding: 0 $sidenav-padding-right;
    line-height: $sidenav-item-height;
    &:hover, &.active { background-color: #ddd; }
  }
  a {
    color: #444;
    display: block;
    font-size: 1rem;
    height: $sidenav-item-height;
    line-height: $sidenav-item-height;
    padding: 0 $sidenav-padding-right;
  }
}


// Touch interaction
.drag-target {
  height: 100%;
  width: 10px;
  position: fixed;
  top: 0;
  z-index: 998;
}


// Hidden side-nav for all sizes
.side-nav.fixed {
  a {
    display: block;
    padding: 0 $sidenav-padding-right;
    color: #444;
  }
}


// Fixed side-nav shown
.side-nav.fixed {
  left: 0;
  position: fixed;

  // Right Align
  &.right-aligned {
    right: 0;
    left: auto;
  }
}

// Fixed sideNav hide on smaller
@media #{$medium-and-down} {
  .side-nav.fixed {
    left: -105%;

    &.right-aligned {
      right: -105%;
      left: auto;
    }
  }
}


.side-nav .collapsible-body li.active,
.side-nav.fixed .collapsible-body li.active {
  background-color: $primary-color;
  a {
    color: $sidenav-bg-color;
  }
}


#sidenav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  height: 120vh;
  background-color: rgba(0,0,0,.5);
  z-index: 997;

  will-change: opacity;
}
