
div.logo {
  width: 100%;
  min-height: 150px;
}

div.logo-effect {
  @extend .logo;
  img {
    height: 100px;
    @include transition(all ease-in-out .2s);
    &:hover {
      transform: scale(1.3);
    }
  }
}
