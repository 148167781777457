
/**
  LAYOUT

  Handles the general structure of the document
  (fonts, color, layout, variables)
*/

$primary-color: #1160a8; // Main theme color (Blue)
$secondary-color: darken($primary-color, 10);
$red-color: #9B0505;
$footer-bg-color: $primary-color;
$button-color: $primary-color;
$light-grey: #F6F6F6;

$text-color: #7a7a7a;
$title-color: darken($text-color,15);

$input-focus-color: white;
$input-border-color: white;
$input-disabled-color: white;

$medium-screen: 1200px;
$medium-screen-up: 1200px;

$small-screen: 800px;
$small-screen-up: 800px;


body {
    font-size: 20px; /* Spécifie la taille de la police par défaut */
    color: $text-color;
    text-align: left;
    h1, h2, h3, h4, h5, h6 {
        color: $title-color;
    }
}

/**
   MATERIALIZE

   CSS Framework used to fit with the Material Design guidelines,
   enabling to have a beautiful and functional design
*/
@import "materialize/materialize";


/**
   PRISMJS

   CSS + JS library in order to have color highlighting in Java
   (for the Choco Solver tuto)
*/
@import "prismjs/prism.css";


/**
  MODULES

  A module is a specific element of the HTML (in most cases a class),
  having its own scope.

  The child are specialized elements of a module are prefixed by the parent's name
*/
@import "modules/nav";
@import "modules/slider";
@import "modules/header";
@import "modules/logo";
@import "modules/config";
@import "modules/news";

.shadowed {
  box-shadow: 0 0 10px grey;
}

.banner .btn {
  background-color: $secondary-color;
  &:hover {
      background-color: $primary-color;
  }
}

.grey-banner {
    background-color: $light-grey;
}

a.brand-logo {
  left: 0;
}

#cookie-banner-message {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 255;
}

.fb-like {
  width: 100% !important;

  span[style] {
    width: 100% !important;

    iframe {
      width: 100% !important;
    }
  }
}

.responsive-code {
  overflow-x: auto;
  //position: relative;
  //top: 3px;
  vertical-align: bottom;
  display: inline-block;
  max-width: 100%;
}

@media screen and (max-width:  900px) {
  h1, h2, h3 {
    font-size: 3em;
  }

  h4 {
    font-size: 2em;
  }
}

em {
   color: $primary-color;
   font-style: normal;
}

.nomargin{
   margin:0 !important;
}

.flex-center{
   display:flex;
   align-items:center;
}

@media screen and (max-width: 600px) {
  .flex-center{
    flex-direction: column;
  }
}

.error {
  margin-top: 0;
  font-weight: bold;
  color: lighten(orange, 20);
}

.check-list {
    list-style: none; /* Supprimez les puces standard */
}

.check-list li {
    padding-left: 25px; /* Ajoutez un espacement à gauche pour l'image */
    background: url('../images/icons/check.svg') no-repeat left center; /* Utilisez votre image SVG comme puce */
    background-size: 16px; /* Ajustez la taille de l'image SVG selon vos besoins */
    margin-bottom: 5px; /* Ajoutez un espacement entre les éléments de liste */
    text-align: left; /* Alignez le texte à gauche */
}

// A colored banner with white text
*.banner {
  color: white;
  h1, h2, h3, h4, h5, h6, em, a, .icon, .ul, .li {
    color: white
  }
  .check-list li {
    padding-left: 25px; /* Ajoutez un espacement à gauche pour l'image */
    background: url('../images/icons/check-white.svg') no-repeat left center; /* Utilisez votre image SVG comme puce */
    background-size: 16px; /* Ajustez la taille de l'image SVG selon vos besoins */
    margin-bottom: 5px; /* Ajoutez un espacement entre les éléments de liste */
    text-align: left; /* Alignez le texte à gauche */
  }
  background-color: $primary-color;
  text-align: center;

  #map {
    color:  black;
  }
}

// Transparent background for the cards (see services)
.card .card-reveal {
  background: rgba(255, 255, 255, 0.9);
}

// Default rendering (chrome like) for the lists
ul.browser-default li {
  list-style-type: disc;
}

// A higher size for the container
.container {
  width: 90%;
}

// A margin for the images (otherwise, the surrounding text is stuck to the img)
img.separated {
  margin: 5px;
}

img {
    max-width:100%
}

.icon{
  i {
    font-size: 100px;
  }
}

// The news table, displaying for each news its date and its title
.table {
  h5 {
    font-size: 16px;
  }
  p {
    font-size: 14px;
    font-style: italic;
  }
  h5, p {
    margin: 0;
  }
}



.card {


  .card-content {
    padding: 20px 0;
    text-align: center;
    .card-title {
        font-weight: bold;
        color: $text-color;
    }
  }

  .card-reveal {
    padding: 0;
  }

  div.top {
    height: 350px;
    padding: 20px;
    width: 100%;
    overflow-y: auto;
  }

  div.bottom {
    height: 20px;
  }
}

.img.grey {
    filter: grayscale(0.80);
}

div.img-index {
  @media screen and (min-width:  600px) {
    display: flex;
    align-items: center;
  }
}

.link-image {
  width:100%;
}

iframe {
  @media screen and (min-width: 1000px){
    width:450px;
    height:253px;
  }
  max-width: 500px;
  height: auto;
}

.centered {
    text-align: center;
}

.righted {
    text-align: right;
}

.lefted {
    text-align: left;
}

.tuto-tag {
    display: inline-block;
    padding: 0px 10px;
    font-size: 14px;
    background-color: $primary-color; /* Couleur de la bulle #ff7f0e*/
    color: white;
    border-radius: 20px; /* Bord arrondi */
}

a.no-recolor {
    color: inherit;
}

.grey-border {
    border: 2px dashed $light-grey; /* Bordure grise de 2px */
    border-radius: 8px; /* Coins arrondis de 8px */
    padding: 20px; /* Espacement intérieur */
}

/* Styles pour les appareils mobiles */
@media only screen and (max-width: 600px) {
  // Header Styles
  h1 {
      font-size: 3rem; /* Modifier la taille de la police comme souhaité */
  }
  h2 {
    font-size: 3rem; /* Modifier la taille de la police comme souhaité */
  }
  h3 {
    font-size: 2.5rem; /* Modifier la taille de la police comme souhaité */
  }
  h4 {
    font-size: 2rem; /* Modifier la taille de la police comme souhaité */
  }
}

.section {
    margin:0;
    padding:1px;
}

.tuile {
  margin: 20px;
  min-height: 320px;
  min-width: 315px;
  .title {
    padding: 20px;
    border-top-left-radius: 15px;  /* Arrondir le coin haut-gauche */
    margin: 0; /* Supprime les marges par défaut du titre */
    color: white; /* Couleur du texte du titre */
  }
  .txt {
    border-top-left-radius: 0px;  /* Arrondir le coin haut-gauche */
    border-bottom-right-radius: 15px;  /* Arrondir le coin bas-droit */
    border-top-right-radius: 0;  /* Coin haut-droit non arrondi */
    border-bottom-left-radius: 0;  /* Coin bas-gauche non arrondi */
    border: 3px solid;
    text-align: center;
    .check-list {
      padding: 25px;
    }
  }
}

.tuile-1 {
  .title {
    background-color: $primary-color;
  }
  .txt {
    border-color: $primary-color;
  }
}

.tuile-2 {
  .title {
    background-color: $secondary-color;
  }
  .txt {
    border-color: $secondary-color;
  }
}

.tuile-3 {
  .title {
    background-color: $red-color;
  }
  .txt {
    border-color: $red-color;
  }
}

.freuder {
    border: 3px solid $secondary-color;
    border-radius: 15px 0px 15px 0px;
    color: $primary-color;
}

// Styles pour les titres sur bandeau image (même rendu que hxxx, mais pas hxxx pour du SEO)
.h2like {
  @extend h2;
  display: block;
}
.h3like {
  @extend h3;
  display: block;
}
.h4like {
  @extend h4;
  display: block;
}
.h5like {
  @extend h5;
  display: block;
}
