@font-face {
    font-family: "Red Hat Display";
    src: local(Red Hat Display Thin), url('../fonts/Red_Hat_Display/static/RedHatDisplay-Thin.ttf');
    src: url("../fonts/Red_Hat_Display/static/RedHatDisplay-Thin.eot?#iefix") format('embedded-opentype'),
        //url("../fonts/Red_Hat_Display/static/RedHatDisplay-Thin.woff2") format("woff2"),
        //url("../fonts/Red_Hat_Display/static/RedHatDisplay-Thin.woff") format("woff"),
        url("../fonts/Red_Hat_Display/static/RedHatDisplay-Thin.ttf") format("truetype");
    font-display: fallback;
    font-weight: 200;
}
@font-face {
    font-family: "Red Hat Display";
    src: local(Red Hat Display Light), url('../fonts/Red_Hat_Display/static/RedHatDisplay-Light.ttf');
    src: url("../fonts/Red_Hat_Display/static/RedHatDisplay-Light.eot?#iefix") format('embedded-opentype'),
        //url("../fonts/Red_Hat_Display/static/RedHatDisplay-Light.woff2") format("woff2"),
        //url("../fonts/Red_Hat_Display/static/RedHatDisplay-Light.woff") format("woff"),
        url("../fonts/Red_Hat_Display/static/RedHatDisplay-Light.ttf") format("truetype");
    font-display: fallback;
    font-weight: 300;
}

@font-face {
    font-family: "Red Hat Display";
    src: local(Red Hat Display Regular), url('../fonts/Red_Hat_Display/static/RedHatDisplay-Regular.ttf');
    src: url("../fonts/Red_Hat_Display/static/RedHatDisplay-Regular.eot?#iefix") format('embedded-opentype'),
        //url("../fonts/Red_Hat_Display/static/RedHatDisplay-Regular.woff2") format("woff2"),
        //url("../fonts/Red_Hat_Display/static/RedHatDisplay-Regular.woff") format("woff"),
        url("../fonts/Red_Hat_Display/static/RedHatDisplay-Regular.ttf") format("truetype");
    font-display: fallback;
    font-weight: 400;
}

@font-face {
    font-family: "Red Hat Display";
    src: url('../fonts/Red_Hat_Display/static/RedHatDisplay-Medium.ttf');
    src: url("../fonts/Red_Hat_Display/static/RedHatDisplay-Medium.eot?#iefix") format('embedded-opentype'),
        //url("../fonts/Red_Hat_Display/static/RedHatDisplay-Medium.woff2") format("woff2"),
        //url("../fonts/Red_Hat_Display/static/RedHatDisplay-Medium.woff") format("woff"),
        url("../fonts/Red_Hat_Display/static/RedHatDisplay-Medium.ttf") format("truetype");
    font-display: fallback;
    font-weight: 500;
}

@font-face {
    font-family: "Red Hat Display";
    src: url('../fonts/Red_Hat_Display/static/RedHatDisplay-Bold.ttf');
    src: url("../fonts/Red_Hat_Display/static/RedHatDisplay-Bold.eot?#iefix") format('embedded-opentype'),
        //url("../fonts/Red_Hat_Display/static/RedHatDisplay-Bold.woff2") format("woff2"),
        //url("../fonts/Red_Hat_Display/static/RedHatDisplay-Bold.woff") format("woff"),
        url("../fonts/Red_Hat_Display/static/RedHatDisplay-Bold.ttf") format("truetype");
    font-display: fallback;
    font-weight: 700;
}
