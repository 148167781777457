

nav {
  text-transform: uppercase;
  background-color:$primary-color;
  ul li {
    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
    &.active {
      font-weight: bold;
    }
  }


  a.button-collapse {
    @extend .hide-on-med-and-up;
  }

}


.brand-logo {


  @media #{$medium-and-down} {
    left: 0 !important;
    transform: translateX(0) !important;

  }

  @media #{$small-and-down} {
    left: 50% !important;
    transform: translateX(-50%) !important;

    &.left, &.right {
      padding: 0;
      transform: none;
    }

    &.left { left: 0.5rem; }
    &.right {
      right: 0.5rem;
      left: auto;
    }
  }
}

div.navbar-fixed {
  position: fixed;
}